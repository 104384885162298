import Auth from "@/components/Auth.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import authHttp from "@/http/auth";
import { useRouter } from "@/lib/router";
import { helperMixin } from "@/mixins";
import { defineComponent } from "vue";
export default defineComponent({
    components: { BaseFormGroup, BaseLink, Auth },
    mixins: [helperMixin],
    computed: {
        authHttp,
        resetState() {
            return this.token && this.email;
        },
        submitButtonLoadingText() {
            if (this.resetState) {
                return this.trans("password_reset.changing");
            }
            return this.trans("password_reset.sending");
        },
        submitButtonText() {
            if (this.resetState) {
                return this.trans("password_reset.change");
            }
            return this.trans("password_reset.send");
        },
        title() {
            if (this.resetState) {
                return this.trans("password_reset.title_has_token", {
                    email: this.email,
                });
            }
            return this.trans("password_reset.title");
        },
    },
    data() {
        return {
            loading: false,
            token: "",
            email: "",
            password: "",
            password_confirmation: "",
            errorMessage: "",
            successMessage: "",
        };
    },
    mounted() {
        var _a, _b, _c;
        this.setPageTitle(this.trans("layout.forgot_password_page_title"));
        const decodedToken = ((_a = this.currentRoute.params) === null || _a === void 0 ? void 0 : _a.passwordResetToken)
            ? atob(this.currentRoute.params.passwordResetToken)
            : "";
        if (decodedToken) {
            const decodedTokenSplit = decodedToken.split(":");
            this.token = (_b = decodedTokenSplit[0]) !== null && _b !== void 0 ? _b : "";
            this.email = (_c = decodedTokenSplit[1]) !== null && _c !== void 0 ? _c : "";
            if (!this.token || !this.email) {
                useRouter().push({
                    name: this.RouteNames.PasswordReset,
                });
                return;
            }
            this.validateToken();
        }
    },
    methods: {
        async submit() {
            this.loading = true;
            if (this.resetState) {
                const { status, message, response, validationErrors } = await this.authHttp.resetPassword({
                    token: this.token,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                });
                if (status === 200) {
                    this.successMessage = message;
                    window.open(response.data);
                    useRouter().push({
                        name: this.RouteNames.Login,
                    });
                }
                else if (status === 422 && validationErrors) {
                    this.validationErrors = validationErrors;
                }
                else {
                    this.errorMessage = message;
                }
            }
            else {
                const { status, message, validationErrors } = await this.authHttp.sendPasswordResetLink(this.email);
                if (status === 200) {
                    this.email = "";
                    this.successMessage = message;
                }
                else if (status === 422 && validationErrors) {
                    this.validationErrors = validationErrors;
                }
                else {
                    this.errorMessage = message;
                }
            }
            this.loading = false;
        },
        async validateToken() {
            const { status } = await this.authHttp.validatePasswordResetToken(this.token, this.email);
            if (status !== 200) {
                this.token = "";
                this.email = "";
                useRouter().push({
                    name: this.RouteNames.PasswordReset,
                    params: {
                        passwordResetToken: "",
                    },
                });
            }
            this.setPageTitle(this.trans("layout.reset_password_page_title"));
        },
    },
});
